<template>
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_864_16864)">
    <rect width="72" height="72" rx="36" fill="white"/>
    <path d="M36 0C28.8799 0 21.9197 2.11136 15.9995 6.06709C10.0793 10.0228 5.46511 15.6453 2.74035 22.2234C0.0155982 28.8015 -0.697322 36.0399 0.691746 43.0233C2.08081 50.0066 5.50948 56.4212 10.5442 61.4558C15.5789 66.4905 21.9934 69.9192 28.9768 71.3083C35.9601 72.6973 43.1985 71.9844 49.7766 69.2597C56.3548 66.5349 61.9772 61.9207 65.9329 56.0005C69.8886 50.0804 72 43.1201 72 36C71.9886 26.4557 68.1921 17.3056 61.4432 10.5568C54.6944 3.80795 45.5443 0.0114326 36 0V0ZM53.5824 30.1824L33.7824 49.9824C33.3645 50.4004 32.8684 50.732 32.3224 50.9582C31.7763 51.1844 31.1911 51.3008 30.6 51.3008C30.009 51.3008 29.4237 51.1844 28.8777 50.9582C28.3316 50.732 27.8355 50.4004 27.4176 49.9824L18.4176 40.9824C17.5736 40.1384 17.0994 38.9936 17.0994 37.8C17.0994 36.6064 17.5736 35.4616 18.4176 34.6176C19.2616 33.7736 20.4064 33.2994 21.6 33.2994C22.7936 33.2994 23.9384 33.7736 24.7824 34.6176L30.6 40.4352L47.2176 23.8176C47.6355 23.3997 48.1317 23.0682 48.6777 22.842C49.2237 22.6158 49.809 22.4994 50.4 22.4994C50.991 22.4994 51.5763 22.6158 52.1223 22.842C52.6684 23.0682 53.1645 23.3997 53.5824 23.8176C54.0003 24.2355 54.3318 24.7317 54.558 25.2777C54.7842 25.8237 54.9006 26.409 54.9006 27C54.9006 27.591 54.7842 28.1763 54.558 28.7223C54.3318 29.2683 54.0003 29.7645 53.5824 30.1824Z" fill="#5BD9A4"/>
    </g>
    <defs>
    <clipPath id="clip0_864_16864">
    <rect width="72" height="72" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>
