<template>
  <ion-page>
    <ion-header
      class="ion-no-border"
      :class="isDark ? 'header-dark' : 'header'"
    >
      <ion-toolbar>
        <div class="navbar-container">
          <span class="navbar-title">
            {{ service.name }} @ {{ service.start_time }}
          </span>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="container">
        <div>
          <div class="icon">
            <CheckmarkAltIcon />
          </div>

          <h1>Check-in successful!</h1>

          <div class="print-info">
            <PrintIcon />
            <span>Your labels are now printing...</span>
          </div>
        </div>

        <div class="people">
          <div v-for="person in filteredAttendees" :key="person.id" class="row">
            <div class="info">
              <img
                v-if="person.avatar_image && person.avatar_image.length"
                :src="person.avatar_image"
                class="pic"
              />

              <template v-else>
                <span
                  class="pic default"
                  :style="{ backgroundColor: person.avatar_color }"
                >
                  {{ person.first_name.substring(0, 1) }}{{ person.last_name.substring(0, 1) }}
                </span>
              </template>

              <div>
                <h3>{{ person.first_name }} {{ person.last_name }}</h3>
                <p v-if="person.school_grade">
                  {{ getSchoolGradeLabel(person.school_grade) }} • {{ service.name }} @ {{ service.start_time }}
                </p>
              </div>
            </div>

            <ion-chip>
              <span>Checked-in</span>
              <Checkmark />
            </ion-chip>
          </div>
        </div>
      </div>
    </ion-content>

    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <div>
          <Button
            text="Return to start"
            variant="primary-bold"
            @click="
              $router.push({
                name: 'LocationServiceSearch',
                params: { locationId: location.id, serviceId: service.id },
              })
            "
          />
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import {
    IonContent,
    IonPage,
    IonChip,
    IonToolbar,
    IonFooter,
    IonHeader,
  } from '@ionic/vue';

  import Button from '@/components/shared/buttons/Button.vue';
  import Checkmark from '@/components/shared/icons/Checkmark';
  import CheckmarkAltIcon from '@/components/shared/icons/CheckmarkAltIcon';
  import PrintIcon from '@/components/shared/icons/PrintIcon';

  import { isPlatform } from '@ionic/vue';
  import { Printer } from '@ionic-native/printer/ngx';
  import { jsPDF } from "jspdf";
  import printJS from 'print-js';

  export default {
    name: 'CheckinSuccess',

    components: {
      IonHeader,
      IonContent,
      IonPage,
      IonFooter,
      IonToolbar,
      IonChip,
      Button,
      Checkmark,
      PrintIcon,
      CheckmarkAltIcon,
    },

    computed: {
      ...mapState({
        theme: (state) => state.theme,
        user: (state) => state.auth.user,
        location: (state) => state.locations.current,
        service: (state) => state.services.current,
        attendees: (state) => state.checkin.attendees,
        gradeOptions: (state) => state.people.gradeOptions,
      }),

      ...mapGetters({
        getSchoolGradeLabel: 'people/getSchoolGradeLabel',
      }),

      filteredAttendees() {
        return this.attendees.filter((person) => {
          return person.first_name && person.last_name;
        });
      },

      isDark() {
        return this.theme == 'dark';
      },
    },

    created() {
      this.checkData();
    },

    ionViewWillEnter() {
      this.checkData();

      if(!window.Cypress) {
        this.createPDF('stickers');
      }
    },

    methods: {
      checkData() {
        if (!this.location) {
          this.$router.push({ name: 'Locations' });
        } else if (!this.service) {
          this.$router.push({
            name: 'LocationServices',
            param: { locationId: this.location.id },
          });
        }
      },

      async createPDF () {
        var doc = new jsPDF({
          unit: 'mm',
          format: [70, 70]
        });

        doc.setFontSize(24);

        for (let i = 0; i < this.filteredAttendees.length; i++) {
          const person = this.filteredAttendees[i];

          doc.text(15, 25, person.first_name);
          doc.text(15, 45, person.last_name);

          if(i + 1 < this.filteredAttendees.length) {
            doc.addPage();
          }
          
        }

        const base64 = doc.output('datauristring');

        this.openPrintOptions(base64);
      },

      async openPrintOptions(base64){

        if(isPlatform('ios')) {
          const printer = new Printer();

          const content = 'base64://' + base64.split(',')[1]

          if(printer.isAvailable()) {
            printer.print(content);
          }
        } else {
          const content = base64.split(',')[1]

          printJS({printable: content, type: 'pdf', base64: true})
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-header.header {
    ion-toolbar {
      --border-style: none;
      --background: #181f36;
      height: 80px;

      .navbar-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.8rem;

        .navbar-title {
          font-size: 16px;
          font-weight: 700;
          color: white;
          margin-left: 0.8rem;
        }
      }
    }
  }

  ion-content {
    --background: rgba(24, 31, 54, 0.03);

    .container {
      flex: 1 1 0%;
      margin-top: 45px;

      .icon {
        display: flex;
        justify-content: center;
      }

      h1 {
        --color: #181f36;
        margin-top: 32px;
        opacity: 0.7;
        font-size: 24px;
        line-height: 120%;
      }

      .print-info {
        margin-top: 20px;
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
          color: #4064c0;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    .spinner-container {
      height: 100vh;
      width: 100%;
      background: rgba(0, 0, 0, 0.2);
      position: absolute !important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .people {
    margin-top: 36px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #181f36;
      width: 650px;
      height: 86px;
      left: 0px;
      top: 0px;
      padding-inline: 24px;
      margin-bottom: 20px;
      border: 1px solid rgba(80, 95, 115, 0.2);
      box-sizing: border-box;
      border-radius: 12px;
      background-color: white;

      .info {
        display: flex;
        align-items: center;

        ion-checkbox {
          margin-right: 16px;
        }

        .pic {
          width: 48px;
          height: 48px;
          border-radius: 50%;

          &.default {
            background-color: #181f36;
            color: white;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          margin: 0%;
          margin-inline: 16px;
        }

        p {
          margin: 6px 0px 0px 0px;
          color: #181f36;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          opacity: 60%;
          margin-inline: 16px;
        }
      }

      ion-chip {
        --background: rgba(91, 217, 164, 0.1);
        border: 1.5px solid #5bd9a4;
        border-radius: 250px;
        color: rgba(91, 217, 164, 1);
        font-size: 18px;
        padding: 20px 15px;

        span {
          margin-right: 12px;
          font-weight: 500;
        }
      }

      ion-button {
        height: 49px;
        width: 49px;
        --padding-start: 3px;
        --padding-end: 3px;
        --border-color: #e9e9e9;
        --background-activated: rgba(64, 100, 192, 0.7);
      }
    }
  }

  ion-footer {
    ion-toolbar {
      --background: #ffffff;
      box-shadow: 0px 0px 12px rgba(191, 191, 191, 0.25);
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 124px;
      }
    }
  }
</style>
